import {
  Stripe,
  PaymentMethodResult,
  StripeCardNumberElement,
  PaymentIntentResult,
  SetupIntentResult
} from '@stripe/stripe-js';
import {LimeConfig} from "../../utils/limeConfig";

export interface IStripeCardDetails {
  name: string;
  card: StripeCardNumberElement;
  postcode: string;
}

export function confirmPaymentWithExistingCard(stripe: Stripe, clientSecret: string) {
  return stripe.confirmCardPayment(clientSecret);
}

export function confirmPaymentWithNewCard(stripe: Stripe, clientSecret: string, cardDetails: IStripeCardDetails, config: LimeConfig) {
  return new Promise<PaymentIntentResult>((resolve, reject) => {
    createPaymentMethod(stripe, cardDetails, config).then((response) => {
      if (response.error) {
        resolve(response);
      } else {
        stripe.confirmCardPayment(clientSecret, {
          payment_method: response.paymentMethod.id,
          save_payment_method: true,
          setup_future_usage: "off_session"
        }).then(resolve).catch(reject)
      }
    }).catch(reject);
  });
}

export function confirmNewCardSetup(stripe: Stripe, clientSecret: string, cardDetails: IStripeCardDetails, config: LimeConfig) {
  return new Promise<SetupIntentResult>((resolve, reject) => {
    if (!cardDetails.name || cardDetails.name.length == 0 || !cardDetails.postcode || cardDetails.postcode.length == 0) {
      reject();
    } else {
      createPaymentMethod(stripe, cardDetails, config).then((response) => {
        if (response.error) {
          resolve(response);
        } else {
          stripe.confirmCardSetup(clientSecret, {
            payment_method: response.paymentMethod.id
          }).then(resolve).catch(reject)
        }
      }).catch(reject);
    }
  });
}

function createPaymentMethod(stripe: Stripe, cardDetails: IStripeCardDetails, config: LimeConfig) {
  return new Promise<PaymentMethodResult>((resolve, reject) => {
    stripe.createPaymentMethod({
      type: 'card',
      card: cardDetails.card,
      billing_details: {
        name: cardDetails.name,
        address: {
          postal_code: cardDetails.postcode,
          country: 'GB'
        }
      }
    }).then((response) => {
      if (response.paymentMethod &&
        response.paymentMethod.card &&
        response.paymentMethod.card.country != 'GB' &&
        !config.allowNonUkCardPayments) {
        resolve({
          error: {
            type: "card_error",
            message: "You can only use cards registered in the UK for payment"
          }
        });
      }
      resolve(response);
    }).catch(reject);
  });
}