import React, {useState} from 'react';
import PaymentCard from "./PaymentCard";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe, Stripe} from "@stripe/stripe-js";
import {Invoice, Payment, PaymentStatusId} from "./types";
import {limeApiGet} from "../../utils/api";
import {getClaimValue} from "../../utils/authUser";
import LimeClaimTypes from "../../models/limeClaimTypes";
import {useAuth} from "react-oidc-context";
import LoadingSpinner from "../Shared/LoadingSpinner/LoadingSpinner";
import {LimeConfig} from "../../utils/limeConfig";
import {useLocation} from "react-router-dom";
import AlertCircle from "../../images/alert-circle.svg";

function PaymentMethod() {
  const auth = useAuth();
  const [updatePaymentMethod] = useState<boolean>(useLocation().state?.updatePaymentMethod);
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [invoice, setInvoice] = useState<Invoice>();
  const [invoiceLoading, setInvoiceLoading] = useState<boolean>(true);
  const [latestPayment, setLatestPayment] = useState<Payment | null>(null);

  React.useEffect(() => {
    (async () => {
      const config = await limeApiGet<LimeConfig>('configuration', auth);
      setStripe(await loadStripe(config.stripeApiKey));
      await refreshInvoice();
      await refreshLatestPayment();
    })();
  }, []);

  async function refreshInvoice() {
    setInvoiceLoading(true);
    let orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
    const data = await limeApiGet<Invoice[]>(`organisations/${orgUuid}/invoices`, auth)
    if (data.length > 0) {
      setInvoice(data[0]);
    }

    setInvoiceLoading(false);
  }

  async function refreshLatestPayment() {
    const orgUuid = getClaimValue(auth.user, LimeClaimTypes.OrganisationUuid);
    const payments = await limeApiGet<Payment[]>(`organisations/${orgUuid}/payments`, auth);
    setLatestPayment(payments && payments.length > 0 ? payments[0] : null);
  }

  function displayAmount() {
    return latestPayment?.amount.toLocaleString('en-GB', { style: 'currency', currency: 'GBP'});
  }

  return(
    <div>
      {
        latestPayment && latestPayment.statusId == PaymentStatusId.Failed &&
          <div className="bg-action-required rounded-lg p-md mb-lg">
              <div className="flex justify-start">
                  <img className="w-10 h-10 my-auto" src={AlertCircle} alt="alert"/>
                  <div className="mx-lg">
                      <h4>Automatic Payment Notice</h4>
                      <p>
                        You have an outstanding balance of {displayAmount()} due to issues with recent payment
                        attempt(s). If you change your payment method below, we'll automatically attempt to charge the
                        outstanding amount on your new card.
                      </p>
                  </div>
              </div>
          </div>
      }
      <h1 className="mb-md">Billing</h1>
        <h3 className="mb-sm">{updatePaymentMethod ? 'Change ' : 'Add '}Payment Method</h3>
        <p className="text-p1 mb-lg">This card will be used to pay your monthly invoices for Limes products and services.</p>
        <div className="w-[30rem]">
          <LoadingSpinner loading={invoiceLoading}>
            {
              invoice && stripe &&
                <>
                    <div className="bg-super-light-grey w-full py-lg px-xl rounded-md mb-md">
                        <h3>Payment Details</h3>
                        <p className="text-p1">Your monthly payment amount is:</p>
                        <h4>
                          { invoice
                            ? invoice.total.toLocaleString('en-GB', {
                              style: 'currency',
                              currency: 'GBP'})
                            : '£-'}
                        </h4>
                    </div>
                    <Elements stripe={stripe}>
                        <PaymentCard stripe={stripe} payment={latestPayment} onPaymentProcessed={refreshLatestPayment} />
                    </Elements>
                </>
            }
          </LoadingSpinner>
        </div>
    </div>
  );
}

export default PaymentMethod;